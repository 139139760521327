// --------------------------------------------------------
// Polyfills
// --------------------------------------------------------
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Array.prototype.includes';
import 'whatwg-fetch'
import Promise from 'promise-polyfill';

if (!window.Promise) {
    window.Promise = Promise;
}

// Lazysizes
// https://github.com/aFarkas/lazysizes
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit.min';
import 'lazysizes/plugins/respimg/ls.respimg.min';

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 300;
window.lazySizesConfig.loadMode = 1;



// --------------------------------------------------------
// Modules
// --------------------------------------------------------

// Main Menu
import('./modules/main-menu.js').then((MainMenu) => {
    new MainMenu.default();
});

// Search
import('./modules/search.js').then((Search) => {
    new Search.default();
});

// Sponsors Block
if (document.querySelector('.js-sponsors-init')) {
    import('./modules/sponsors.js').then((Sponsors) => {
        new Sponsors.default();
    });
}

// Gallery
if (document.querySelector('.js-gallery-init')) {
    import('./modules/gallery.js').then((Gallery) => {
        new Gallery.default();
    });
}

// Image Carousel
if (document.querySelector('.js-image-carousel-init')) {
    import('./modules/image-carousel.js').then((ImageCarousel) => {
        new ImageCarousel.default();
    });
}

// Homepage Carousel
if (document.querySelector('.js-homepage-carousel-init')) {
    import('./modules/homepage-carousel.js').then((HomepageCarousel) => {
        new HomepageCarousel.default();
    });
}

// Links Dropdown
if (document.querySelector('.js-links-dropdown-init')) {
    import('./modules/links-dropdown.js').then((LinksDropdown) => {
        new LinksDropdown.default();
    });
}

// Find A Station
if (document.querySelector('.js-fas-init')) {
    import('./modules/find-a-station.js').then((FindAStation) => {
        new FindAStation.default();
    });
}